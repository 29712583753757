import React from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import "./header.css";
import { user_logout } from "../redux/actions/authActions";
import { switchTheme } from "../redux/actions/coinDBAction";
import NotificationBanner from "./BannerData";

function saveLogoutTextFile() {
  // const text = 'LOGGING OUT: Header.jsx back function';

  // // Create a Blob with the text content
  // const blob = new Blob([text], { type: 'text/plain' });

  // // Create a URL for the Blob
  // const url = window.URL.createObjectURL(blob);

  // // Create a link element for downloading the file
  // const a = document.createElement('a');
  // a.href = url;
  // a.download = 'headerLogOut.txt';

  // // Trigger a click event on the link to download the file
  // a.click();

  // // Clean up resources
  // window.URL.revokeObjectURL(url);
}


export default function Header(props) {
  const dispatch = useDispatch();
  const location = useLocation();
  const { isLoggedIn, switch_theme } = useSelector(
    (state) => state.AuthReducer
  );
  const { webData } = useSelector((state) => state.websiteDBReducer);
  async function backRouter() {
    dispatch(
      user_logout(() => {
        props.history.replace("/exchange/btc-inr");
        document.location.reload();
      })
    );
    saveLogoutTextFile()
  }
  async function swtchTheme() {
    if (switch_theme == "dark") {
      dispatch(switchTheme("light"));
      localStorage.removeItem("tradingview.current_theme.name");
      localStorage.setItem("exchange_inrx_theme", "light");
      const body = document.getElementsByTagName("body")[0];
      body.classList.add("is_light");
      body.classList.remove("is_dark");

      var icon = document.querySelector("#theme_icon");
      icon.classList.add("fa-sun");
      icon.classList.remove("fa-moon");
    } else {
      dispatch(switchTheme("dark"));
      localStorage.removeItem("tradingview.current_theme.name");
      localStorage.setItem("exchange_inrx_theme", "dark");
      const body = document.getElementsByTagName("body")[0];
      body.classList.add("is_dark");
      body.classList.remove("is_light");

      var icon = document.querySelector("#theme_icon");

      icon.classList.add("fa-moon");
      icon.classList.remove("fa-sun");
    }
  }

  return (
    
    <>

      {/* <NotificationBanner/> */}
      <div className="container-fluid border-bottom bg-dark">
        
        <div class="container-fluid">
        <div className="header-wrapper">
  <p className="header-info">
    Dear RBC users, We are glad to announce that we are shifting our Trade Engine and details will be announced shortly. We request all to please convert your Asset to Ruby Coin for the safety of your assets.
  </p>
</div>

          <nav className="navbar navbar-expand-lg navbar-dark bg-dark py-0 fixed-top">
            <Link className="navbar-brand" to="/">
              <img
                className=""
                height="35"
                src="/img/ctskola-0.png"
                alt="logo"
                width="137"
              />
            </Link>
            <span className="brand-title">
              {" "}
              {webData.logo_with_title === 1 ? webData.website_short_name : ""}
            </span>
            <button
              class="navbar-toggler"
              type="button"
              data-bs-toggle="offcanvas"
              data-bs-target="#ca-navbar"
              aria-controls="ca-navbar "
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span class="navbar-toggler-icon"></span>
            </button>
            <div
              id="ca-navbar"
              className="offcanvas offcanvas-end navbar-collapse-- manu_header"
            >
              <div class="offcanvas-header">
                <h5 class="offcanvas-title" id="ca-navbar">
                  <Link className="navbar-brand" to="/">
                    <img
                      className=""
                      height="35"
                      src="/img/ctskola-0.png"
                      alt="logo"
                      width="137"
                    />
                  </Link>
                </h5>
                <button
                  type="button"
                  class="btn-close btn-close-white"
                  data-bs-dismiss="offcanvas"
                  aria-label="Close"
                ></button>
              </div>
              <div className="offcanvas-body">
                <ul
                  id="nav--"
                  className="navbar-nav justify-content-end flex-grow-1 pe-3"
                >
                  <li className="nav-item">
                    <Link className="nav-link theme-color-text" to="/">
                      Home
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      className=" nav-link theme-color-text"
                      to="/exchange/btc-inr"
                    >
                      Exchange
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      className="nav-link theme-color-text"
                      to="/p2pnew/rbc-inr"
                    >
                      P2P
                    </Link>
                  </li>
                  {isLoggedIn ? (
                    <li className="nav-item">
                      <Link className="nav-link" to="/wallet">
                        Wallets
                      </Link>
                    </li>
                  ) : null}
                  {isLoggedIn ? (
                    <li className="nav-item">
                      <Link className="nav-link" to="/pending-complete-order-list">
                        Orders
                      </Link>
                    </li>
                  ) : null}
                  <div className="d-flex">
                    <ul className="navbar-nav">
                      {location.pathname !== "/login" &&
                      location.pathname !== "/otp" &&
                      location.pathname !== "/kyc" &&
                      !isLoggedIn ? (
                        <li class="nav-item">
                          <Link className="nav-link mx-0" to="/login">
                            {" "}
                            <span className="signin-btn">Login</span>{" "}
                          </Link>
                        </li>
                      ) : null}
                      {location.pathname !== "/create" &&
                      location.pathname !== "/otp" &&
                      location.pathname !== "/kyc" &&
                      !isLoggedIn ? (
                        <li class="nav-item">
                          <Link className="nav-link mx-0" to="/create">
                            <span className="signup-btn">Register</span>
                          </Link>
                        </li>
                      ) : null}
                      {isLoggedIn ? (
                        <>
                          <li class="nav-item dropdown border-start">
                            <a
                              class="nav-link dropdown-toggle"
                              href="#"
                              data-bs-toggle="dropdown"
                            >
                              {" "}
                              <i class="fa fa-circle-user fs-6"></i>{" "}
                            </a>

                            <ul class="dropdown-menu dropdown-menu-end dropdown-menu-dark">
                              <Link to="/profile" className="dropdown-item">
                                Account
                              </Link>

                              <a
                                className="dropdown-item bg-danger text-white"
                                onClick={() => {
                                  backRouter();
                                }}
                              >
                                <i className="fa fa-sign-out text-white"></i>{" "}
                                Logout
                              </a>
                            </ul>
                          </li>
                          <li className="nav-item d-none d-lg-block border-start">
                            <a
                              href="/notification"
                              className="nav-link theme-color-text"
                            >
                              <i className="fa fa-bell-o fs-6"></i>
                            </a>
                          </li>
                        </>
                      ) : null}

                      <li className="nav-item border-start">
                        <a
                          onClick={() => {
                            swtchTheme();
                          }}
                          className="nav-link"
                          style={{ cursor: "pointer" }}
                        >
                          <i class="fa-solid fa-sun fs-6" id="theme_icon"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </ul>
              </div>

              {/* <div className="sing-up-button d-block d-lg-none">
                {location.pathname !== "/login" && !isLoggedIn ? (
                  <Link to="/login">Login</Link>
                ) : null}
                {location.pathname !== "/create" && !isLoggedIn ? (
                  <Link to="/create">Register</Link>
                ) : null}
                {isLoggedIn ? (
                  <>
                    <Link to="/profile" className="nav-link">
                      Account
                    </Link>

                    <p
                      className="nav-link my-2"
                      onClick={() => {
                        backRouter();
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      Logout
                    </p>
                  </>
                ) : null}
              </div> */}
              
            </div>
          </nav>
         
        </div>
       
      </div>
     
    </>
  );
}
